import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';



// routing lazy loading
const routes: Routes = [
  { path: '', loadChildren: './pages/home/home.module#HomeModule' },
  { path: 'search', loadChildren: './pages/search/search.module#SearchModule' },
  { path: 'tobook', loadChildren: './pages/tobook/tobook.module#TobookModule' },
  {
    path: 'extendedoffer',
    loadChildren:
      './pages/extendedoffer/extendedoffer.module#ExtendedofferModule'
  },
  {
    path: 'warunki-rezerwacji',
    loadChildren: './pages/conditions/conditions.module#ConditionsModule'
  },
  {
    path: 'polityka-prywatnosci',
    loadChildren: './pages/policy/policy.module#PolicyModule'
  },
  {
    path: 'rezerwacja/grupowa',
    loadChildren: './pages/groupreservation/groupreservation.module#GroupreservationModule'
  },
  {
    path: 'wyslano-do-operatora',
    loadChildren: './pages/sendtooperator/sendtooperator.module#SendtooperatorModule'
  },
  {
    path: 'warunki-przewozu',
    loadChildren: './pages/conditionscarriage/conditionscarriage.module#ConditionscarriageModule'
  },
  {
    path: 'dlafirm',
    loadChildren: './pages/forcompany/forcompany.module#ForcompanyModule'
  },
  {
    path: 'jak-kupowac-bilet-lotniczy',
    loadChildren: './pages/howtobuy/howtobuy.module#HowtobuyModule'
  },
  {
    path: 'kontakt',
    loadChildren: './pages/contact/contact.module#ContactModule'
  },
  {
    path: 'booking',
    loadChildren: './pages/booking/booking.module#BookingModule'
  },
  {
    path: 'bookingok',
    loadChildren: './pages/bookingok/bookingok.module#BookingokModule'
  },
  {
    path: 'bookingerror',
    loadChildren: './pages/bookingerror/bookingerror.module#BookingerrorModule'
  },
  {
    path: 'informacje-dla-pasazerow-linii-lotniczych',
    loadChildren: './pages/articles/articles.module#ArticlesModule'
  },
  {
    path: 'wiadomosci/:pageid',
    loadChildren: './pages/articles/articles.module#ArticlesModule'
  },
  {
    path: 'news/:slug',
    loadChildren: './pages/article/article.module#ArticleModule'
  },
  {
    path: 'not-found',
    loadChildren:
      './pages/page-not-found/page-not-found.module#PageNotFoundModule'
  },
  { path: '**', redirectTo: '/not-found' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
